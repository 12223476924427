<template>
  <div class="mt-8">
    <div class="mb-4">
      <p class="font-medium mb-2 text-primary">LDAPID</p>
      <vs-input
        v-validate="'required'"
        data-vv-validate-on="blur"
        name="mobile"
        icon-no-border
        icon="icon icon-user"
        placeholder="Enter..."
        icon-pack="feather"
        v-model="mobile"
        class="w-full"
      />
      <p class="text-danger text-sm mt-2">{{ errors.first("mobile") }}</p>
    </div>

    <div class="mb-4">
      <p class="font-medium mb-2 text-primary">Password</p>
      <vs-input
        data-vv-validate-on="change"
        v-validate="'required'"
        type="password"
        name="password"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        v-model="password"
        class="w-full"
        @keydown.enter="loginJWT"
      />
      <span class="text-danger text-sm mt-2">{{
        errors.first("password")
      }}</span>
    </div>
    <div class="flex">
      <!-- <vs-checkbox
        v-model="isTermsConditionAccepted"
        class="mt-6"
      ></vs-checkbox> -->
      <p class="mt-6">
        By logging in you agree to accept our
        <a class="cursor-pointer" @click.prevent="openTerms"
          >Terms &amp; Conditions</a
        >.
      </p>
    </div>
    <div class="flex flex-wrap justify-between my-5">
      <div class="w-full border-divlogin">
        <vs-button
          :disabled="!validateForm"
          @click="loginJWT"
          class="w-full mb-3"
          >Login</vs-button
        >
      </div>
    </div>
    <div class="flex-wrap justify-between mb-3">
      <p class="text-danger mb-2">Not yet registered ?</p>
      <vs-button type="border" class="w-full" @click="registerUser"
        >Register Now</vs-button
      >
    </div>
  </div>
</template>

<script>
import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
const LDAPRepository = ClientRepositoryFactory.get("LDAP");
import jwt from "jsonwebtoken";
const uuid = require("@/Core/config/uuid");
import reCaptchaMixin from "@/Client/Kotak/mixins/reCaptchaMixin";
import validationMixin from "@/Core/mixins/validationMixin";

export default {
  mixins: [reCaptchaMixin, validationMixin],
  data() {
    return {
      mobile: "",
      password: "",
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.mobile != "" && this.password != "";
    },
  },
  watch: {
    mobile(val) {
      this.specialCharacterValidation(val, "mobile");
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()
        this.$router.push("/home").catch(() => {});
        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    async loginJWT() {
 
      // const reCaptcha = await this.recaptcha("adminLogin");
      const uniqueId = uuid.getUniqueBrowserId();
      this.$store.dispatch("storeuniqueDeviceId", uniqueId);
      localStorage.setItem("biri", uniqueId);

      const payload = {
        username: this.mobile,
        password: this.password,
        uniqueId: uniqueId,
        isLogin: true,
        // token: reCaptcha
      };
      try {
        this.$vs.loading();

        const { data } = await LDAPRepository.authenticate(payload);

        this.$vs.loading.close();

        if (data.success && data.ResponseCode == 103) {
            const { data } = await LDAPRepository.authenticate(payload);
          this.loginAlert("Your account is not yet activated");
        } else if (data.success && data.ResponseCode == 102) {
          this.loginAlert(
            "Your account is blocked. Kindly contact admin to unblock your account"
          );
        } else if (data.success && data.ResponseCode == 100) {
          this.assignRole(data);
          this.loginToApp(data);
        } else {
          this.$vs.notify({
            title: "Error",
            text: data.message || "Invalid Credentials",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } catch (error) {
        this.$vs.loading.close();
      }
    },
    assignRole(role) {
      const payload = {
        isCommunityOwner: role.isCommunityOwner,
        isCommunityManager: role.isCommunityManager,
      };
      const encodedString = jwt.sign(payload, "secret", { expiresIn: "1h" });
      localStorage.setItem("userData", encodedString);
      if (role.isCommunityOwner) {
        this.$acl.change("superadmin");
      } else if (role.isCommunityManager) {
        this.$acl.change("admin");
      } else {
        this.$acl.change("editor");
      }
    },
    loginAlert(text) {
      this.$swal({
        icon: "warning",
        title: text,
      });
    },
    registerUser() {
      this.$router.push("/pages/customadminregister").catch(() => {});
    },
    async loginToApp(response) {
      try {
        this.$vs.loading.close();
        this.setToken(response.token);
        this.startUserSession(response.token);
        if (response.managertoken != null) {
          this.setManagerToken(response.managertoken);
        }
        // this.startUserSession(response.token);
        this.routeToHome();
      } catch (error) {
        this.$vs.loading.close();
      }
    },
    openTerms() {
      let routeData = this.$router.resolve({ name: "page-terms" });
      window.open(routeData.href, "_blank");
    },
    setToken(token) {
      localStorage.removeItem("token");
      localStorage.setItem("token", token);
      // this.$store.state.token = jwt.decode(localStorage.getItem("token"));
      this.$store.dispatch(
        "storeUserToken",
        jwt.decode(localStorage.getItem("token"))
      );
    },
    setManagerToken(token) {
      localStorage.setItem("managertoken", token);
      this.$store.state.managertoken = jwt.decode(
        localStorage.getItem("managertoken")
      );
    },
    startUserSession(token) {
      this.$session.start();
      this.$session.set("jwt", token);
    },
    routeToHome() {
      this.$router.push("/home").catch(() => {});
    },
    autoLogin() {
      if (localStorage.getItem("token")) {
        jwt.verify(
          localStorage.getItem("token"),
          process.env.VUE_APP_RAAZKIBAAT + localStorage.getItem("biri"),
          async (err) => {
            if (err) {
              if (err.name === "TokenExpiredError") {
                // this.routeToHome();
              }
            } else {
              this.routeToHome();
            }
          }
        );
      } else {
        return;
      }
    },
  },
  mounted() {
    // this.$store.dispatch("whitelist/checkWhitelisted").then(data => {
    //   if (!data.data.status) {
    //     this.$router.push("/pages/not-authorized");
    //   }
    // });
    // this.autoLogin();
    // if (
    //   localStorage.getItem("token") &&
    //   this.$session.get("jwt") == localStorage.getItem("token") &&
    //   jwt.verify(localStorage.getItem("token"), "my_secret_key")
    // ) {
    //   debugger;
    //   this.$router.push("/home").catch(() => {});
    // }
  },
  // async beforeCreate() {
  //   try {
  //     this.$vs.loading();

  //     const { data } = await this.$store.dispatch("whitelist/checkWhitelisted");

  //     if (!data.status) {
  //       this.$router.push("/pages/not-authorized");
  //     }
  //     this.$vs.loading.close();
  //   } catch (error) {
  //     if (error) {
  //       this.$vs.loading.close();
  //     }
  //   }
  // },
};
</script>
<style>
body {
  width: 100%;
}
.vs-input--placeholder.normal:focus {
  padding: 0;
}
</style>
