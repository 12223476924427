export default {
  methods: {
    validateRegex(value, field, regex) {
      if (!value && !field && !regex) return;
      this.$set(this, field, value.replace(regex, ""));
    },
    specialCharacterValidation(value, field) {
      let regex = /[^A-Z0-9.]/gi;
      this.validateRegex(value, field, regex);
    },
    scriptValidation(value, field) {
      let regex = /<script[\s\S]*?>[\s\S]*?<\/script>/gi;
      this.validateRegex(value, field, regex);
    }
  }
};
