export default {
  methods: {
    recaptcha(action) {
      return new Promise(async (resolve, reject) => {
        try {
          const token = await this.$recaptcha(action);
          resolve(token);
        } catch (err) {
          reject(false);
        }
      });
    }
  }
};
