<template>
  <div>
    <vs-input
      v-validate="'required|min:3'"
      data-vv-validate-on="blur"
      name="mobile"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      label-placeholder="Mobile"
      v-model="mobile"
      class="w-full"
      type="tel"
    />
    <span class="text-danger text-sm">{{ errors.first("mobile") }}</span>

    <vs-input
      data-vv-validate-on="change"
      v-validate="'required'"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      label-placeholder="Password"
      v-model="password"
      class="w-full mt-6"
      @keydown.enter="loginJWT"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>
    <div class="flex">
      <!-- <vs-checkbox
        v-model="isTermsConditionAccepted"
        class="mt-6"
      ></vs-checkbox> -->
      <p class="mt-6">
        By logging in you agree to accept our
        <a class="cursor-pointer" @click.prevent="openTerms"
          >Terms &amp; Conditions</a
        >.
      </p>
    </div>
    <div class="flex flex-wrap justify-between my-5">
      <!-- <router-link to="/pages/forgot-password"
        >Forgot Password?</router-link
      >-->
      <div class="w-full border-divlogin">
        <vs-button
          :disabled="!validateForm"
          @click="loginJWT"
          class="w-full mb-3"
          >Login</vs-button
        >
        <div class="flex flex-wrap justify-end my-1 w-full">
          <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox> -->
          <router-link to="/pages/customforgotpassword"
            >Forgot Password?</router-link
          >
        </div>
      </div>
    </div>
    <div class="flex-wrap justify-between mb-3">
      <p class="text-danger mb-2">Not yet registered ?</p>
      <vs-button type="border" class="w-full" @click="registerUser"
        >Register Now</vs-button
      >

      <!-- <vs-button @click="popupActive = true">Pop</vs-button> -->
    </div>

    <!-- <div class="flex flex-wrap justify-between mb-3">
      <vs-button type="border" @click="registerUser">Register</vs-button>
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div> -->
  </div>
</template>

<script>
//import axios from "axios";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const userRepository = RepositoryFactory.get("user");
import jwt from "jsonwebtoken";
const uuid = require("@/Core/config/uuid");
export default {
  data() {
    return {
      mobile: "",
      password: "",
      checkbox_remember_me: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.mobile != "" && this.password != "";
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()
        this.$router.push("/community-home").catch(() => {});
        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    async loginJWT() {
     
      let self = this;
      const uniqueId = uuid.getUniqueBrowserId();
      // this.$store.dispatch("storeuniqueDeviceId",uniqueId);
      localStorage.setItem("biri", uniqueId);

      // Loading
      this.$vs.loading();

      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
          uniqueId: uniqueId,
        },
        userData: {
          mobile: this.mobile,
          password: this.password,
        },
      };
      const { data } = await userRepository.getUser(obj);
console.log(data,"datadatadatadatadatadatadata")
      ////debugger;

      if (data.data.ResponseCode == 100) {
        if (data.data.userData.usersDetails_Status != 1) {
          this.$vs.loading.close();
          this.$swal({
            icon: "warning",
            title: "Your account is not yet activated",
          });
        } else if (data.data.userData.usersDetails_BlockStatus == 1) {
          this.$vs.loading.close();
          this.$swal({
            icon: "warning",
            title:
              "Your account is blocked. Kindly contact admin to unblock your account",
          });
        } else {
          localStorage.setItem(
            "bsacl",
            jwt.sign(data.data, "my_secret_key", { expiresIn: 60 * 60 * 24 })
          );
          if (data.data.isCommunityOwner) {
            this.$acl.change("superadmin");
          } else if (data.data.isCommunityManager) {
            this.$acl.change("admin");
          } else {
            this.$acl.change("editor");
          }
          // var loginId = currentCommunityName.communityName + "_" + self.mobile;
          // loginId = loginId.replace(/ /g, "");
          // var searchParamsRecruiter = { login: loginId };
          // if (data.data.userData != null) {
          //   var recruiterProfile = {
          //     login: loginId,
          //     password: "12345678",
          //     full_name: self.displayName
          //   };
          // }
          // const usersInfo = await AuthService.getUserFromServerById(
          //   searchParamsRecruiter
          // );

          // if (usersInfo == false) {
          //   AuthService.signUp(recruiterProfile)
          //     .then(() => {
          //       // alert("success");
          //       self.loginToApp(data);
          //     })
          //     .catch(error => {
          //       // alert(error);
          //     });
          // } else {
          //   var userCredentials = { login: loginId, password: "12345678" };
          //   AuthService.signIn(userCredentials)
          //     .then(() => {
          //       // alert("success login");
          self.loginToApp(data);
          //     })
          //     .catch(error => {
          //       // alert(error);
          //     });
          // }
        }
      } else {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: data.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
      this.$vs.loading.close(); //close loder
      // self.loginToApp(response);
      //    })
      //   .catch(function(error) {
      //   console.log(error);
      //   self.showLoginError(error);
      // });

      // this.$store.dispatch('auth/loginJWT', payload)
      //   .then(() => {
      //     this.$vs.loading.close()
      //     this.$router.push('/community-home').catch(() => {})
      //   })
      //   .catch(error => {
      //     this.$vs.loading.close()
      //     this.$vs.notify({
      //       title: 'Error',
      //       text: error.message,
      //       iconPack: 'feather',
      //       icon: 'icon-alert-circle',
      //       color: 'danger'
      //     })
      //   })
    },
    registerUser() {
      // if (!this.checkLogin()) return;
      this.$router.push("/pages/customadminregister").catch(() => {});
    },
    async loginToApp(response) {
      ////debugger;
      // var obj = {
      //   ID: response.data.userMobile,
      //   connectycubeId: JSON.parse(
      //     localStorage.getItem("CURRENT_USER_SESSION_KEY")
      //   ).id,
      //   communityName: currentCommunityName.communityName
      // };
      // const { data } = await userRepository.UpdateCoonectyCubeId(obj);
      // console.log(data, "RESP");

      //debugger;
      // if (response.data.ResponseCode == 100) {
      //debugger;
      this.$vs.loading.close();
      localStorage.removeItem("token");
      localStorage.setItem("token", response.token);
      this.$store.dispatch(
        "storeUserToken",
        jwt.decode(localStorage.getItem("token"))
      );

      // this.$store.state.token = jwt.decode(localStorage.getItem("token"));
      if (response.data.managertoken != null) {
        localStorage.setItem("managertoken", response.managertoken);
        this.$store.state.managertoken = jwt.decode(
          localStorage.getItem("managertoken")
        );
      }
      this.$session.start();
      this.$session.set("jwt", response.token);

      this.$router.push("/community-home").catch(() => {});
      // } else {
      //   ////debugger;
      //   this.$vs.loading.close();
      //   this.$vs.notify({
      //     title: "Error",
      //     text: response.message,
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //     color: "danger"
      //   });
      // }
    },
    showLoginError(error) {
      this.$vs.loading.close();
      this.$vs.notify({
        title: "Error",
        text: error.message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
      });
    },
    openTerms() {
      // this.$router.push("/pages/terms").catch(() => {});
      let routeData = this.$router.resolve({ name: "page-terms" });
      window.open(routeData.href, "_blank");
    },
  },

  mounted: function () {
    // this.initUser();
    if (
      localStorage.getItem("token") &&
      this.$session.get("jwt") == localStorage.getItem("token") &&
      jwt.verify(
        localStorage.getItem("token"),
        process.env.VUE_APP_RAAZKIBAAT + localStorage.getItem("biri")
      )
    ) {
      this.$router.push("/community-home").catch(() => {});
    }
  },
};
</script>
<style>
body {
  width: 100%;
}
</style>
